export default {
    name: 'BooksByCategory',

    components: {},
    data() {
        return {
            books: [],
            search: '',
            selectedItem: 1,
            spinner: false
        }
    },
    beforeMount(){
        this.$store.dispatch('setSpinner', true);
    },
    async mounted() {
        try {
            await Promise.all([
                this.$store.dispatch("fetchAllBooksByCategory", this.$root.$route.params.id)
            ])
            let booksList = this.$store.getters.getAllBooksByCategory;
            booksList.map(b => {
                b.formatString = "";
                b.formats.map((f, index) => {
                    b.formatString += index == 0 ? "FORMATO " + f.type.toUpperCase() : ", " + f.type.toUpperCase();
                });
                this.books.push(b);
            })
        } catch(error){
            console.log(error);
            alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
        } finally {
            this.$store.dispatch('setSpinner', false);
        }
    },
    computed: {
        booksFiltered() {
            return this.books.filter(book => {
                if (!this.search) return this.books;
                return (book.title.toLowerCase().includes(this.search.toLowerCase()) ||
                    book.author_sort.toLowerCase().includes(this.search.toLowerCase()));
            })
        }
    },
    methods: {
        clearSearch() {
            this.search = "";
        },
        openBook(book) {
            let bookTitleUrl = book.title.toLowerCase().split(" ").join("_");
            this.$router.push({
                name: "Book",
                params: {
                    book: book,
                    bookTitleUrl: bookTitleUrl
                }
            })
        }
    }
}